// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"src/modules/channel/config.ts"
);
import.meta.hot.lastModified = "1717594159233.8381";
}
// REMIX HMR END

export const config = {
    path: '/dashboard/settings/channels',
    apiPath: '/api/channel'
}

export const channelTokenCookie = 'vendure_channel'
export const channelTokenKey = 'vendure-token'
export const DEFAULT_CHANNEL_TOKEN = '__default_channel__'